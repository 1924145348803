//Tailwind
import './scss/app.scss';

//Alpine JS
import Alpine from 'alpinejs';
window.Alpine = Alpine;

Alpine.start();


//Highway
import Highway from '@dogstudio/highway';

//Highway Renderers
import HomeRenderer from "./js/highway/renderer/home-renderer";

//Highway Transitions
import Fade from "./js/highway/transition/fade";


import Appear from "@explose/appear";

const appear = new Appear({
    animations: {
        imageReveal: {
            from: { scale: 1.05, opacity: 0.7 },
            to: { scale: 1, opacity: 1 },
            duration: 2,
            appearEase: 'back.easeOut'
        },
        top2bottom: {
            from: { y: -20, opacity: 0 },
            to: { y: 0, opacity: 1 },
            duration: 2,
            appearEase: 'expo'
        },
        bottom2top: {
            from: { y: 20, opacity: 0 },
            to: { y: 0, opacity: 1 },
            duration: 2,
            appearEase: 'expo'
        },
        paragraph: {
            from: { y: -10, opacity: 0 },
            to: { y: 0, opacity: 1 },
            duration: .7,
            appearEase: 'none'
        }
    }
});

/***
 * Highway
 */

// Call Highway.Core once.
// Store it in a variable to use events
const H = new Highway.Core({
    renderers: {
        homepage: HomeRenderer,
        default: HomeRenderer
    },
    transitions: {
        name: Fade,
        default: Fade
    }
}
);


// Listen Events

H.on('NAVIGATE_END', ({ to }) => {
    // Call Quicklink
    //listen();
    if (!location.hash.length)
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    else
        document.querySelector(location.hash).scrollIntoView(); 
        // window.scrollTo({
        //     top: document.querySelector(location.hash).getBoundingClientRect().top + window.scrollY,
        //     behavior: 'smooth'
        // });
    appear.update();
});


window.addEventListener('load', () => {
    // Call Quicklink
    //listen();

});